// Here you can add other styles
.modal-content {
  height: 100%;
}

.maximizedModal {
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  height: 100%;
  margin: 0;
}

.privilegeDiv > div > button {
  border: 1px solid #e4e7ea;
  box-shadow: none;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  color: #5c6873;
}

option[value=""][disabled] {
  display: none;
}

.maximizedModal > .modal-content {
  max-height: 100vh;
}

.custom-search-box {
  transition: width 0.5s;
}

.custom-search-box:focus-within {
  width: 250px !important;
  outline: none;
}

.custom-input-search:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: #e4e7ea;
}

.nonMaximizedModal {
  max-width: 795px;
}

.nonMaximizedModal2 {
  max-width: 895px;
}

.custom-script-div > .table-responsive {
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.custom-results-div > .table-responsive {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.CodeMirror-placeholder {
  color: #5c6873 !important;
}

.CodeMirror-readonly .CodeMirror-cursor {
  display: none !important;
}

.results-code-mirror-wrapper
  > .CodeMirror-wrap
  > .CodeMirror-scroll
  > .CodeMirror-sizer
  > div
  > .CodeMirror-lines
  > div
  > .CodeMirror-cursors
  > .CodeMirror-cursor {
  display: none !important;
}

.sql-tablerow-link:hover {
  color: #20a8d8;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 11px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  text-align: center;
  color: white;
}

.apimodal {
  max-width: 750px;
}

.modalIcon {
  cursor: pointer;
  font-size: 16px !important;
  position: absolute;
  right: 30px;
  top: 21px;
}

.activeTab {
  color: #2f353a !important;
  background: #fff !important;
  border-color: #c8ced3 !important;
  border-left-color: #fff !important;
}

.react-codemirror2 {
  width: 100%;
  height: 100%;
  border: 1px solid #c8ced3;
  border-right-color: #eae8e8;
}

.nav-tabs .nav-item {
  margin-bottom: 0px !important;
}

.nav-tabs {
  border-bottom: none !important;
}

.CodeMirror {
  height: 100% !important;
  font-size: 14px;
}

.overlay-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(138, 138, 138, 0.38);
  z-index: 2040;
}

.overlay-container > div > .fa-refresh {
  position: absolute;
  left: 50%;
  top: 45%;
}

.disable-link {
  color: gray;
}

.disable-link:hover {
  text-decoration: none;
  cursor: initial;
  color: gray;
}

.group-api-form .multiselect-container .search-wrapper .chip {
  padding: 10px !important;
  background: rgb(240, 240, 240) !important;
  margin-bottom: 5px !important;
  margin-right: 0px !important;
  border-radius: 0rem !important;
  display: block !important;
  align-items: center !important;
  font-size: 13px !important;
  color: #23282c !important;
  white-space: nowrap !important;
  border: 1px solid rgb(204, 204, 204) !important;
}

.group-api-form .multiselect-container .search-wrapper .chip::before {
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f142  \f142";
  padding-right: 10px;
  padding-left: 5px;
}

[class^="icon_"]:before,
[class*=" icon_"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: 0;
  display: inline-block;
  text-decoration: inherit;
  color: #9999af;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._1ruIhoPvyfSHg9LQB0CI6p:before {
  content: "\e801" !important;
}

.custom-dropdown {
  max-height: 190px;
  min-height: auto;
  overflow-y: auto;
}

/** Sidebar scrolling Css */

.sidebar .nav-dropdown.open > .nav-dropdown-items {
  min-height: auto;
  max-height: 200px !important;
  overflow-y: auto !important;
}

.sidebar .nav-dropdown.open > .nav-dropdown-items::-webkit-scrollbar {
  width: 3px;
}

.sidebar .nav-dropdown.open > .nav-dropdown-items::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar .nav-dropdown.open > .nav-dropdown-items::-webkit-scrollbar-thumb {
  background: #8a949c;
  opacity: 0.5;
}

.sidebar .nav-dropdown.open > .nav-dropdown-items::-webkit-scrollbar-thumb:hover {
  background: rgba(65, 69, 70, 0.7);
}

/** */

.custom-dropdown::-webkit-scrollbar {
  width: 3px;
}

.custom-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-dropdown::-webkit-scrollbar-thumb {
  background: #b3b3b3;
}

.custom-dropdown::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 194, 199);
}

/** Custom Scrollbar for search box*/

.custom-search-div::-webkit-scrollbar {
  width: 3px;
}

.custom-search-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-search-div::-webkit-scrollbar-thumb {
  background: #b3b3b3;
}

.custom-search-div::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 194, 199);
}

/**  Notifications custom scrollbar css*/

.notification-bar::-webkit-scrollbar {
  width: 3px;
}

.notification-bar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.notification-bar::-webkit-scrollbar-thumb {
  background: #b3b3b3;
}

.notification-bar::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 194, 199);
}

.notificationBtn {
  background-color: #ffffff !important;
  color: black !important;
  border: 1px solid #d4d4d4;
  outline: none;
}

/** */

.information-tooltip {
  background-color: blue !important;
  color: blue !important;
}

.select__control {
  width: 100% !important;
}

.select__placeholder {
  margin-left: 7px !important;
}

#userMultiSelect > .select__control > .select__value-container > div {
  margin-bottom: -1px;
}

#userMultiSelect > .select__control > .select__value-container > div > div {
  padding: 5px 5px;
}

@media screen and (max-width: 998px) {
  .switch-row {
    width: 100% !important;
  }
}
