.card-header {
  background: linear-gradient(120deg, #1a365d 0%, #2c5282 100%) !important;
}

.btn-primary {
  background: linear-gradient(120deg, #1a365d 0%, #2c5282 100%) !important;
  /* background-color: #1a365d !important; */
  /* border-color: #1a365d !important; */
}

.modal-header {
  background-color: #1a365d !important;
}

/* Override form-control styles */
.form-control {
  height: 40px;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  border: 1px solid #e2e8f0 !important;
  background-color: white !important;
  font-size: 14px !important;
  transition: all 0.2s ease !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  padding-top: 5px !important;
}

.form-control:focus {
  border-color: #3b82f6 !important;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25) !important;
  outline: none !important;
}

.form-control:hover {
  border-color: #94a3b8 !important;
}

.form-control:disabled {
  background-color: #f8fafc !important;
  cursor: not-allowed !important;
}

select.form-control {
  padding-right: 36px !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E") !important;
  background-position: right 12px center !important;
  background-repeat: no-repeat !important;
  background-size: 16px !important;
  -webkit-appearance: none !important;
}

.dd-menu-item {
  color: white !important;
}
